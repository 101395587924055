import React from 'react';
import loading from '../assets/loader.gif';


function Loading() {
    return (
        <div className="overlay">
            <div className="loading-spinner">
                <img loading="lazy" src={loading} alt="Loading..." />
            </div>
        </div>
    );
}

export default Loading;
