import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import removetag from "../assets/removetaglogo.png";

import { Link } from "react-router-dom";
import axios from "axios";

function Footer() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    // Scroll to the top when the component mounts or when the location changes
    window.scrollTo(0, 0);

    // Event listener to check scroll position and toggle button visibility
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 100); // Adjust the threshold as needed
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [responseMessage, setResponseMessage] = useState('');





  return (
    <>

      {showScrollToTop && (
        <button
          className="scroll-to-top-btn"
          onClick={handleScrollToTop}
          title="Scroll to Top"
        >
          &#8593;
        </button>
      )}
      <section class="footer  footer-bottom-search bg-dark section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="navbar-brand pt-0 mb-3 ">
                {/* <div className="footerbox">
                  <img class="img-fluid" src={logo} />
                  <div className="footerline"></div>
                </div> */}
                <div className="position-relative">
                  <img loading="lazy" src={removetag} className="headerlogo" alt="" />
                  <h5 className="mb-0 tagline text-white" >spreading hapiness spreading smiles...</h5>
                </div>
              </div>
              <p className="para-box">An initiative by GAMS</p>
            </div>
            <div class="col-md-4">
              <h6>Quick Links</h6>
              <ul className="mb-3">
                <li>
                  <Link to={'/home'}>Home</Link>
                </li>
                <li>
                  <Link to={'/categories'}>Categories</Link>
                </li>
                <li>
                  <Link to={'/magzine'}>Magzine</Link>
                </li>
                {/* <li>
                  <Link to={'/utsava'}>Utsava</Link>
                </li> */}
                <li>
                  <Link to={'/eventspage'}>Events</Link>
                </li>
                <li>
                  <Link to={'/pages'}>Pages</Link>
                </li>
              </ul>
            </div>
            {/* <div class="col-xl-3 col-6">
              <h6>About Us</h6>
              <ul className="mb-3">
                <li>
                  <Link to={'/faq'}>Faq</Link>
                </li>
                <li>
                  <Link to={'/terms'}>Terms</Link>
                </li>
              </ul>
            </div> */}
            {/* <div class="col-xl-2 col-sm-6">
              <h6>SERVICES</h6>
              <ul>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Pagebuilder</a>
                </li>
              </ul>
            </div>
            <div class="col-xl-2 col-sm-6">
              <h6>CONNECT</h6>
              <ul>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Page Builder</a>
                </li>
                <li>
                  <a href="#">UI Kit</a>
                </li>
                <li>
                  <a href="#">Styleguide</a>
                </li>
                <li>
                  <a href="#">Documentation</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
              </ul>
            </div> */}

            <div class="col-md-4">
              <div className="happening-icon text-center">
                <h5> Social Media</h5>
                <i class="fa-brands fa-twitter"></i>
                <i class="fa-brands fa-facebook"></i>
                <i class="fa-brands fa-instagram"></i>
              </div>
              {/* <h6>Subscribe to our Newsletter</h6>
              <form class="form-inline newsletter-form mb-1 ">
                <div className="input-group">
                  <input
                    type="text"
                    class="form-control mr-sm-2"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    class="btn bg-gradient-primary text-white"
                  >
                    Subscribe
                  </button>
                </div>
              </form> */}
              {/* <small>
                <a href="#">
                  Register now to get updates on{" "}
                  <span class="text-info">Offers and Coupons</span>
                </a>
              </small> */}

            </div>
          </div>
        </div>
      </section>

      {/* <section class="py-2">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="d-flex align-items-center justify-content-between small">
                <p class="m-0">Copyright © Your Website 2020</p>
                <p class="m-0">
                  <a href="https://nitin.frantic.in/faq/">Faq</a>
                  &nbsp; · &nbsp; &nbsp; · &nbsp;
                  <a href="https://nitin.frantic.in/terms/">
                    Terms &amp; Conditions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Footer;






