import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Slider from "react-slick";

import MultipleImages from "../Components/Multipleimages";
import { Button, Modal } from "react-bootstrap";
import Banner from "../Components/Banner";
import logo from "../assets/logo.png";
import CategoriesList from "../Components/CategoriesList";
// import imgtab from "../assets/imgtab.png";
import imgtab from "../assets/imgtab.png";
import voucherlogo from "../assets/voucherlogo.jpg";
import { useNavigate } from "react-router-dom";

import stores1 from "../assets/stores1.avif"
import stores2 from "../assets/stores2.avif"
import stores3 from "../assets/stores3.avif"
import stores4 from "../assets/stores4.avif"
import Geolocater from "../Components/Geolocater";




function Home() {
  var settings = {
    // dots: true,
    infinite: true,
    nav: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var eventsscroll = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768, // define breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [box, setbox] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(true);
  }, []);

  const cardsData = [
    {
      link: "#",
      imageSrc:
        "https://img.freepik.com/free-photo/beautiful_1203-2633.jpg?size=626&ext=jpg",
      logoSrc:
        "https://img.freepik.com/free-photo/beautiful_1203-2633.jpg?size=626&ext=jpg",
      storeName: "MakeMyTrip",
      offerLink: "#",
      offerTitle: "Get Flat 30% OFF on Payment Via RBL Bank",
      expiresIn: "Ends in 18 days",
      discount: "50% OFF",
    },
    {
      link: "#",
      imageSrc:
        "https://img.freepik.com/free-photo/freshly-baked-pizza-rustic-wooden-table-generated-by-ai_24640-82021.jpg",
      logoSrc:
        "https://img.freepik.com/free-photo/freshly-baked-pizza-rustic-wooden-table-generated-by-ai_24640-82021.jpg",
      storeName: "Dominos",
      offerLink: "#",
      offerTitle: "Special Offer: Get Up to 50% Off On Web Hosting",
      expiresIn: "Ends in 18 days",
      discount: "50% OFF",
    },
    {
      link: "#",
      imageSrc:
        "https://img.freepik.com/free-photo/fresh-pasta-with-hearty-bolognese-parmesan-cheese-generated-by-ai_188544-9469.jpg",
      logoSrc:
        "https://img.freepik.com/free-photo/fresh-pasta-with-hearty-bolognese-parmesan-cheese-generated-by-ai_188544-9469.jpg",
      storeName: "Swiggy",
      offerLink: "#",
      offerTitle: "Get Flat 30% OFF on Payment Via RBL Bank",
      expiresIn: "Ends in 18 days",
      discount: "50% OFF",
    },
    {
      link: "#",
      imageSrc:
        "https://img.freepik.com/free-photo/elegant-woolen-jacket-men-winter-fashion-generated-by-ai_188544-34301.jpg",
      logoSrc:
        "https://img.freepik.com/free-photo/elegant-woolen-jacket-men-winter-fashion-generated-by-ai_188544-34301.jpg",
      storeName: "Myntra",
      offerLink: "#",
      offerTitle: "Special Offer: Get Up to 50% Off On Web Hosting",
      expiresIn: "Ends in 18 days",
      discount: "50% OFF",
    },
    // Add more card data objects as needed
  ];
  const navigate = useNavigate();

  const handlelogin = (e) => {
    e.preventDefault();
    setShow(false);
    navigate("");
  };

  const offersData = [
    {
      imageSrc:
        "https://img.freepik.com/free-photo/restaurant-interior_1127-3394.jpg",
      title: "Special Offer: Get Up to 50% Off On Web Hosting",
      originalPrice: "$150",
      discountedPrice: "$80",
      badgeText: "OFFER",
      badgeColor: "success",
      offerDetails: "Flat 50% on all Stores",
    },
    {
      imageSrc:
        "https://img.freepik.com/free-photo/group-friends-eating-restaurant_23-2148006644.jpg",
      title: "New User - Get Flat Rs.100 OFF + Rs.300 Licious Cash",
      originalPrice: "$150",
      discountedPrice: "$80",
      badgeText: "OFFER",
      badgeColor: "success",
      offerDetails: "Flat 50% on all Stores",
    },
    {
      imageSrc:
        "https://img.freepik.com/free-photo/traditional-indian-soup-lentils-indian-dhal-spicy-curry-bowl-spices-herbs-rustic-black-wooden-table_2829-18717.jpg",
      title: "Coupon Code: Get Flat 50% Off On Online Food",
      originalPrice: "$150",
      discountedPrice: "$80",
      badgeText: "OFFER",
      badgeColor: "success",
      offerDetails: "Flat 50% on all Stores",
    },
    {
      imageSrc:
        "https://img.freepik.com/free-photo/young-woman-eating-croissants-cafe_1303-20408.jpg",
      title: "New User - Get Flat Rs.100 OFF + Rs.300 Licious Cash",
      originalPrice: "$150",
      discountedPrice: "$80",
      badgeText: "OFFER",
      badgeColor: "danger",
      offerDetails: "Flat 50% on all Stores",
    },
  ];

  const food = [
    {
      img: "https://images.unsplash.com/photo-1504674900247-0877df9cc836?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Tasty Treats",
      tag: "food",
      para: "Dive into an array of flavorful delights, where every bite promises satisfaction and culinary bliss beyond compare.",
    },
    {
      img: "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Savor Selections",
      tag: "Restaurant",
      para: " Indulge in curated flavors, a symphony of taste and texture designed to elevate your dining experience to new heights.",
    },
    {
      img: "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Culinary Creations",
      tag: "Beauty",
      para: "Discover artistry on the plate, where innovative presentations and timeless recipes unite to create unforgettable gastronomic experiences.",
    },
    {
      img: "https://images.unsplash.com/photo-1515003197210-e0cd71810b5f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Beverage Bliss",
      tag: "food",
      para: "Quench your thirst with refreshing libations, each sip a moment of pure indulgence and relaxation for your senses.",
    },
  ];

  const education = [
    {
      img: "https://plus.unsplash.com/premium_photo-1682125773446-259ce64f9dd7?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Knowledge Quest",
      tag: "food",
      para: "Explore wisdom's depths, embark on a journey of enlightenment, and uncover the universe's secrets within Knowledge Quest's realm.",
    },
    {
      img: "https://images.unsplash.com/photo-1580582932707-520aed937b7b?q=80&w=1332&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Academic Adventures",
      tag: "Restaurant",
      para: " Thrilling voyages into knowledge, where every discovery leads to understanding and every adventure promises enlightenment.",
    },
    {
      img: "https://images.unsplash.com/photo-1577896851231-70ef18881754?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Learning Pathways",
      tag: "Beauty",
      para: "Navigate diverse routes of discovery, explore history's corridors, and innovate at the frontiers of knowledge through Learning Pathways.",
    },
    {
      img: "https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Scholarly Pursuits",
      tag: "food",
      para: "Ignite curiosity, pursue excellence, and unravel the universe's mysteries through Scholarly Pursuits' passionate pursuit of knowledge and understanding.",
    },
  ];

  const entertainment = [
    {
      img: "https://images.unsplash.com/photo-1603190287605-e6ade32fa852?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Showtime Spectacular",
      tag: "food",
      para: "Immerse yourself in the glitz and glamour of Showtime Spectacular, where every performance dazzles and delights with its sheer brilliance and captivating charm."

    },
    {
      img: "https://images.unsplash.com/photo-1499364615650-ec38552f4f34?q=80&w=1372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Festival Frenzy",
      tag: "Restaurant",
      para: "Get swept away by the infectious energy of Festival Frenzy, where the air is filled with laughter, music, and the joy of celebration that knows no bounds."
    },
    {
      img: "https://images.unsplash.com/photo-1514525253161-7a46d19cd819?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      para: "Experience the magic of Stage Sensation, where every act is a masterpiece, and every moment is filled with the thrill of anticipation and the wonder of creativity.",
      title: "Stage Sensation",
      tag: "Beauty",
    },
    {
      img: "https://images.unsplash.com/photo-1510511233900-1982d92bd835?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Concert Carnival",
      tag: "food",
      para: "Join the vibrant rhythm of Concert Carnival, where music fills the air and hearts unite in harmony, creating memories that last a lifetime amidst the electrifying atmosphere."
    },
  ];

  const fashion = [
    {
      "img": "https://images.unsplash.com/photo-1483985988355-763728e1935b?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Elegance Redefined",
      "tag": "food",
      "para": "Discover a new dimension of elegance where style speaks volumes and sophistication meets innovation in every thread and stitch."
    },
    {
      "img": "https://images.unsplash.com/photo-1445205170230-053b83016050?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Modern Chic",
      "tag": "Restaurant",
      "para": "Embrace the allure of modern chic, where contemporary trends and classic styles intertwine to create a fashion statement."
    },
    {
      "img": "https://images.unsplash.com/photo-1537832816519-689ad163238b?q=80&w=1459&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Timeless Elegance",
      "tag": "Beauty",
      "para": "Step into the realm of timeless elegance, where sophistication and grace stand the test of trends and time."
    },
    {
      "img": "https://images.unsplash.com/photo-1490481651871-ab68de25d43d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Bold Statements",
      "tag": "food",
      "para": "Make bold statements with your style, where fashion becomes a canvas for self-expression and individuality."
    }
  ]


  const automobile = [
    {
      "img": "https://images.unsplash.com/photo-1558486799-ebad18202175?q=80&w=1519&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Sleek Design",
      "tag": "food",
      "para": "Explore the world of automobiles with sleek designs that marry form and function for an unparalleled driving experience."
    },
    {
      "img": "https://images.unsplash.com/photo-1527383418406-f85a3b146499?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Performance Excellence",
      "tag": "Restaurant",
      "para": "Experience the thrill of performance excellence as every automobile unleashes power and precision on the open road."
    },
    {
      "img": "https://images.unsplash.com/photo-1633867179970-c54688bcfa33?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Innovative Technology",
      "tag": "Beauty",
      "para": "Discover innovative technology integrated into every automobile, offering safety, comfort, and connectivity like never before."
    },
    {
      "img": "https://images.unsplash.com/photo-1546356594-3bb0e904d8dc?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Luxury Experience",
      "tag": "food",
      "para": "Indulge in a luxury experience with automobiles designed to redefine opulence and elegance in every journey you embark upon."
    }
  ]


  const fitness = [
    {
      "img": "https://images.unsplash.com/photo-1517836357463-d25dfeac3438?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Active Lifestyle",
      tag: "Restaurant",
      "para": "Embrace an active lifestyle and unlock your full potential with fitness routines that invigorate your body, mind, and soul."
    },
    {
      "img": "https://images.unsplash.com/photo-1558611848-73f7eb4001a1?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Strength Training",
      tag: "food",
      "para": "Embark on a journey of strength training and witness your body transform with every rep, every set, and every lift."
    },
    {
      "img": "https://images.unsplash.com/photo-1605296867304-46d5465a13f1?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Yoga and Meditation",
      tag: "Beauty",
      "para": "Find inner peace and balance with the transformative practices of yoga and meditation, fostering harmony within body and soul."
    },
    {
      "img": "https://images.unsplash.com/photo-1561214078-f3247647fc5e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Outdoor Adventures",
      tag: "food",
      "para": "Embark on outdoor adventures that ignite your spirit of exploration, pushing your physical limits and reconnecting you with nature."
    }
  ]


  const popular = [
    {
      img: "https://images.unsplash.com/photo-1569917761875-e2897cee7d80?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      img: "https://images.unsplash.com/photo-1604066867775-43f48e3957d8?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      img: "https://images.unsplash.com/photo-1555686913-2e38a187c840?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      img: "https://plus.unsplash.com/premium_photo-1664372599811-0aa42648d1e6?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];

  const events = [
    {
      "img": "https://images.unsplash.com/photo-1549451371-64aa98a6f660?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Vibrant Celebrations",
      tag: "Restaurant",
      "para": "Immerse yourself in vibrant celebrations filled with music, dance, and joyous moments that create unforgettable memories."
    },
    {
      "img": "https://images.unsplash.com/photo-1604066867775-43f48e3957d8?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Cultural Extravaganza",
      tag: "food",
      "para": "Experience a cultural extravaganza showcasing the diversity of traditions, art, and cuisine from around the world."
    },
    {
      "img": "https://images.unsplash.com/photo-1555686913-2e38a187c840?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Spectacular Events",
      tag: "Beauty",
      "para": "Witness spectacular events filled with excitement, energy, and unforgettable moments that captivate hearts and minds."
    },
    {
      "img": "https://plus.unsplash.com/premium_photo-1664372599811-0aa42648d1e6?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Exclusive Gatherings",
      tag: "food",
      "para": "Join exclusive gatherings where sophistication meets elegance, creating memorable experiences in luxurious settings."
    }
  ]


  const Furniture = [
    {
      "img": "https://plus.unsplash.com/premium_photo-1678402545077-7a9ec2b5e5b8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZnVybml0dXJlfGVufDB8fDB8fHww",
      "title": "Contemporary Elegance",
      tag: "food",
      "para": "Indulge in the allure of contemporary elegance with furniture pieces that redefine sophistication and style in every room."
    },
    {
      "img": "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnVybml0dXJlfGVufDB8fDB8fHww",
      "title": "Modern Minimalism",
      tag: "Restaurant",
      "para": "Embrace the essence of modern minimalism with furniture designs that exude simplicity, functionality, and timeless appeal."
    },
    {
      "img": "https://images.unsplash.com/photo-1524758631624-e2822e304c36?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZnVybml0dXJlfGVufDB8fDB8fHww",
      "title": "Rustic Charm",
      tag: "Beauty",
      "para": "Discover the rustic charm of furniture pieces that evoke warmth, authenticity, and a sense of homeliness in any living space."
    },
    {
      "img": "https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGZ1cm5pdHVyZXxlbnwwfHwwfHx8MA%3D%3D",
      "title": "Timeless Classics",
      tag: "food",
      "para": "Experience the allure of timeless classics with furniture designs that stand the test of trends, offering enduring beauty and grace."
    }
  ]


  const healthcare = [
    {
      "img": "https://plus.unsplash.com/premium_photo-1682141160157-d6a296a65574?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGVhbHRoY2FyZXxlbnwwfHwwfHx8MA%3D%3D",
      "title": "Holistic Care",
      tag: "food",
      "para": "Discover holistic care solutions tailored to nurture your body, mind, and soul, promoting wellness and vitality in every aspect of life."
    },
    {
      "img": "https://images.unsplash.com/photo-1628771065518-0d82f1938462?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGhlYWx0aGNhcmV8ZW58MHx8MHx8fDA%3D",
      "title": "Advanced Technology",
      tag: "Restaurant",
      "para": "Experience advanced technology and innovation in healthcare, revolutionizing diagnostics, treatments, and patient care for better outcomes."
    },
    {
      "img": "https://images.unsplash.com/photo-1576765607924-3f7b8410a787?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGhlYWx0aGNhcmV8ZW58MHx8MHx8fDA%3D",
      "title": "Compassionate Service",
      tag: "Beauty",
      "para": "Receive compassionate service and care from dedicated healthcare professionals committed to your well-being and comfort at every step."
    },
    {
      "img": "https://images.unsplash.com/photo-1607619056574-7b8d3ee536b2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGhlYWx0aGNhcmV8ZW58MHx8MHx8fDA%3D",
      "title": "Wellness Programs",
      tag: "food",
      "para": "Participate in wellness programs designed to empower you with knowledge and resources, fostering a proactive approach to health and well-being."
    }
  ]


  const resorts = [
    {
      "img": "https://images.unsplash.com/photo-1617859047452-8510bcf207fd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Serene Escapes",
      tag: "food",
      "para": "Embark on a journey of tranquility and rejuvenation amidst picturesque landscapes and luxurious amenities, creating unforgettable memories."
    },
    {
      "img": "https://images.unsplash.com/photo-1583037189850-1921ae7c6c22?q=80&w=1975&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Tropical Paradise",
      tag: "Restaurant",
      "para": "Dive into the lap of luxury surrounded by palm-fringed beaches, crystal-clear waters, and sun-kissed sands, promising an idyllic tropical escape."
    },
    {
      "img": "https://images.unsplash.com/photo-1561501878-aabd62634533?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Exotic Retreats",
      tag: "Beauty",
      "para": "Discover exotic retreats where lush greenery, vibrant cultures, and warm hospitality converge to offer an unparalleled vacation experience."
    },
    {
      "img": "https://images.unsplash.com/photo-1582610116397-edb318620f90?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Soothing Seascapes",
      tag: "food",
      "para": "Surrender to the allure of soothing seascapes, where the rhythm of the waves harmonizes with your soul, offering blissful moments of relaxation."
    }
  ]


  const Spiritual = [
    {
      img: "https://img.freepik.com/premium-photo/there-are-many-galactic-stars-night-sky-huge-massive-ganesha-statue-with-red-lanterns-rising-sky-crowds-watching-lantern-festival-generative-ai-ganesha-festival_620624-6596.jpg",
      title: "Ganesh Temple",
      tag: "food",
      para: "Amidst a celestial tapestry, the Ganesh Temple stands adorned with vibrant lanterns, a beacon of tradition and reverence. Beneath the night sky, devotees gather.",
    },
    {
      img: "https://images.unsplash.com/photo-1599840309126-7ece88628ded?q=80&w=1790&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Golden Temple",
      tag: "Restaurant",
      para: "Radiating divine grace, the Golden Temple glows with timeless magnificence. Its shimmering facade reflects the devotion of countless pilgrims.",
    },
    {
      img: "https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Church",
      tag: "Beauty",
      para: "Serene and solemn, the Church stands as a bastion of faith and contemplation. Within its hallowed halls, prayers rise like incense, weaving a tapestry of spiritual solace.",
    },

    {
      img: "https://images.unsplash.com/photo-1590092794015-bce5431c83f4?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Mosque",
      tag: "food",
      para: "Graceful minarets reach towards the heavens, as the Mosque stands as a testament to unity and peace. Within its tranquil walls, the melody of prayers echoes, fostering a sense of harmony.",
    },
  ];

  const property = [
    {
      "img": "https://images.unsplash.com/photo-1609867271967-a82f85c48531?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Modern Urban Living",
      tag: "food",
      "para": "Experience the epitome of modern urban living with sleek designs, cutting-edge amenities, and vibrant communities."
    },
    {
      "img": "https://images.unsplash.com/photo-1627882206813-8c1ffd86efec?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Luxurious Retreats",
      tag: "Restaurant",
      "para": "Indulge in luxurious retreats that redefine opulence, offering unparalleled comfort, elegance, and sophistication."
    },
    {
      "img": "https://images.unsplash.com/photo-1600047509807-ba8f99d2cdde?q=80&w=1892&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Rural Charm",
      tag: "Beauty",
      "para": "Embrace the rural charm with properties nestled amidst serene landscapes, offering tranquility and a closer connection to nature."
    },
    {
      "img": "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "title": "Cozy Homesteads",
      tag: "food",
      "para": "Discover cozy homesteads where comfort meets charm, creating warm and inviting spaces to call home."
    }
  ]



  const utsavstores = [
    {
      "title": "Holistic Care",
      img: stores1,
      tag: "Clothes",
      "para": "Discover holistic care solutions tailored to nurture your body, mind, and soul, promoting wellness and vitality in every aspect of life."
    },
    {
      "title": "Advanced Technology",
      img: stores2,

      tag: "Restaurant",
      "para": "Experience advanced technology and innovation in healthcare, revolutionizing diagnostics, treatments, and patient care for better outcomes."
    },
    {
      "title": "Compassionate Service",
      img: stores3,

      tag: "food",
      "para": "Receive compassionate service and care from dedicated healthcare professionals committed to your well-being and comfort at every step."
    },
    {
      "title": "Wellness Programs",
      img: stores4,

      tag: "food",
      "para": "Participate in wellness programs designed to empower you with knowledge and resources, fostering a proactive approach to health and well-being."
    }
  ]


  const startevent = [
    {
      img: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      date: "22",
      month: "Jan",
      title: "Godatigus Kekava",
      para: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      img: "https://img.freepik.com/free-photo/spa-setting-with-lit-candle-fluffy-towels-fragrant-flowers-promotes-relaxation_91128-4634.jpg?w=1380&t=st=1709202512~exp=1709203112~hmac=18edf870983b73cb6e8736a9aa4c8ebc913acd80b6f3d573629774a5978d197b",
      date: "11",
      month: "May",
      title: "Spa Belgique",
      para: "Lorem ipsum dolor sit amet consectetur."

    },
    {
      img: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      date: "11",
      month: "May",
      title: "Jam J'Ouvert",
      para: "Lorem ipsum dolor sit amet consectetur."

    },
    {
      img: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      date: "11",
      month: "Aug",
      title: "2019 Blockchain Futurist",
      para: "Lorem ipsum dolor sit amet consectetur."

    },
  ]



  return (
    <>

      {/* {box ? (
        <div
          className="fulimage"
          style={{ width: "100%", height: "100vh", position: "relative" }}
        >
          <img
            src="https://images.unsplash.com/photo-1467803738586-46b7eb7b16a1?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="w-100 h-100"
            style={{ zIndex: 0 }}
          />

          <div
            style={{
              position: "absolute",
              bottom: "80px",
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              zIndex: 1,
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                margin: "0 10px",
                fontSize: "16px",
                cursor: "pointer",
                width: "200px",
              }}
              onClick={(e) => setbox(false)}
              className="btn bg-gradient-primary text-white rounded-0"
            >
              Continue with Login
            </button>
            <button
              style={{
                padding: "10px 20px",
                margin: "0 10px",
                fontSize: "16px",
                cursor: "pointer",
                width: "200px",
              }}
              onClick={(e) => setbox(false)}
              className="btn  bg-gradient-primary text-white rounded-0"
            >
              Skip
              <span className="ms-2">
                <i class="fa-solid fa-angles-right"></i>
                <i class="fa-solid fa-angles-right"></i>
              </span>
            </button>
          </div>
        </div>
      ) : ( */}
      <>
        <Header />
        <Banner />

        {/* <Geolocater /> */}

        <section className="mt-5">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <CategoriesList
                  title="Spiritual"
                  para="Spiritual / Devotional"
                  slug="Spiritual"
                  aos="fade-left"
                  timing="1000"
                  data={Spiritual}
                />
                <CategoriesList
                  title="Food & Beverages"
                  para="Food And Beverages"
                  slug="Food"
                  data={food}
                  aos="fade-right"
                  timing="1000"
                />

                <CategoriesList
                  title="Educational & Carrer"
                  para="Education"
                  slug="Education"
                  aos="fade-right"
                  timing="1000"
                  data={education}
                />
                <CategoriesList
                  title="Entertainment & Events"
                  para="Entertainment Categories"
                  slug="Entertainmen"
                  data={entertainment}
                  aos="fade-left"
                  timing="1000"
                />
                <CategoriesList
                  title="Fashion , Footwear & Accessories"
                  para="Fashion Categories"
                  slug="Fashion"
                  data={fashion}
                  aos="fade-right"
                  timing="1000"
                />
                <CategoriesList
                  title="Automobile"
                  para="Automobile Categories"
                  slug="Automobile"
                  aos="fade-left"
                  timing="1000"
                  data={automobile}
                />
                <CategoriesList
                  title="Beauty Care, Fitness Centres , Saloons and Spa"
                  para="Fitness Categories"
                  slug="Fitness"
                  data={fitness}
                  aos="fade-right"
                  timing="1000"
                />
                <CategoriesList
                  title="Entertainment & Events"
                  para="Entertainment and Events"
                  slug="Events"
                  data={fitness}
                  aos="fade-left"
                  timing="1000"
                />
                <CategoriesList
                  title="Furniture & Home Decore"
                  para="Furniture And Home Decore"
                  slug="Furniture"
                  data={Furniture}
                  aos="fade-right"
                  timing="1000"
                />

                <CategoriesList
                  title="Healthcare & Diagnostics"
                  para="Healthcare and Diagnostics"
                  slug="Healthcare"
                  aos="fade-left"
                  timing="1000"
                  data={healthcare}
                />
                <CategoriesList
                  title="Hospitality & Resorts"
                  para="Hospitality and Resorts"
                  slug="Hospitality"
                  data={resorts}
                  aos="fade-right"
                  timing="1000"
                />
                <CategoriesList
                  title="Property & Building Materials"
                  para="Property and Building Materials"
                  slug="Property"
                  data={property}
                  aos="fade-left"
                  timing="1000"
                />

                <CategoriesList
                  title="Popular Utsav Stores"
                  para="Find top related stores"
                  slug="utsav"
                  data={utsavstores}
                  aos="fade-right"
                  timing="1000"
                />
                {/* <CategoriesList
                  title="Popular Utsav Stores"
                  para="Popular Utsav Stores Categories"
                  data={popular}
                /> */}
              </div>
            </div>
          </div>
        </section>
        {/* <section class="section-padding homepage-view-offers">
          <div class="container">
            <div class="row">
              <div class="col-md-5 mx-auto text-center">
                <h3 class="mb-3">Top Offers</h3>
                <p class="text-dark-50 mb-5">
                  Build a beautiful, modern website with flexible Bootstrap
                  components built from scratch.
                </p>
              </div>
            </div>
            <div class="row">
              {cardsData.map((card, index) => (
                <div key={index} className="col-xl-3 col-sm-6 mb-4">
                  <div className="custom-card shadow-sm h-100">
                    <div className="custom-card-image">
                      <a href={card.link}>
                        <img
                          className="img-fluid item-img"
                          src={card.imageSrc}
                          alt={card.title}
                        />
                      </a>
                      <div className="button-g-btn button-g-btn-up">
                        <img
                          className="img-fluid"
                          src={card.logoSrc}
                          alt={card.title}
                        />
                        <span>{card.storeName}</span>
                      </div>
                    </div>
                    <div className="p-3 pt-4">
                      <div className="custom-card-body">
                        <h6 className="mb-3">
                          <a className="text-black" href={card.offerLink}>
                            {card.offerTitle}
                          </a>
                        </h6>
                        <p className="text-gray">
                          <i className="icofont-clock-time"></i>{" "}
                          {card.expiresIn}
                        </p>
                      </div>
                      <div className="custom-card-footer d-flex align-items-center justify-content-between">
                        <span className="text-primary">
                          <i className="icofont-sale-discount"></i>{" "}
                          {card.discount}
                        </span>
                        <a
                          className="btn btn-orange ml-auto"
                          href={card.offerLink}
                        >
                          Get Offer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}

        {/* <section className="section-padding pt-5 sliderimage homepage-great-deals bg-white d-none">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-11">
                <Slider {...settings}>
                  <div>
                    <img
                      alt="placeholder image"
                      src="https://images.unsplash.com/photo-1611403570720-162d8829689a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div>
                    <img
                      alt="placeholder image"
                      src="https://images.unsplash.com/photo-1611403570720-162d8829689a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div>
                    <img
                      alt="placeholder image"
                      src="https://images.unsplash.com/photo-1611403570720-162d8829689a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div>
                    <img
                      alt="placeholder image"
                      src="https://images.unsplash.com/photo-1611403570720-162d8829689a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      className="img-fluid rounded"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section class="section-padding homepage-rest-block">
          <div class="container">
            <div class="row">
              <div class="col-md-5 mx-auto text-center">
                <h3 class="mb-3 text-white">Top Restaurant</h3>
                <p class="text-white-50 mb-5">
                  Build a beautiful, modern website with flexible Bootstrap
                  components built from scratch.
                </p>
              </div>
            </div>
            <div class="row">
              {offersData.map((data) => {
                return (
                  <>
                    <div className="col-xl-3 col-sm-6 mb-4">
                      <div className={`custom-card shadow-sm bg-white h-100`}>
                        <div className="custom-card-image">
                          <a href="#">
                            <img
                              className="img-fluid item-img"
                              src={data.imageSrc}
                              alt=""
                            />
                          </a>
                          <div
                            className={`shape shape-bottom shape-fluid-x svg-shim text-white bg-${data.badgeColor}`}
                          >
                            
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="custom-card-body">
                            <h6 className="mb-1">
                              <a className="text-black" href="#">
                                {data.title}
                              </a>
                            </h6>
                            <p className="text-gray mb-2">
                              <i className="icofont-price"></i>{" "}
                              <s>{data.originalPrice}</s>{" "}
                              <span className="text-black font-weight-bold">
                                {data.discountedPrice}
                              </span>
                            </p>
                          </div>
                          <div className="custom-card-badge mt-2">
                            <span className={`badge badge-${data.badgeColor}`}>
                              {data.badgeText}
                            </span>{" "}
                            {data.offerDetails}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            
          </div>
        </section> */}

        {/* <section>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <CategoriesList
                  title="Started Events"
                  para="Events Categories"
                  data={events}
                />
              </div>
            </div>
          </div>
        </section> */}


        <section className="bestvouchersection">
          <div className="container">
            <div className="row bg-white customshadow rounded-3 p-3 mb-5 align-items-center">
              <div className="col-md-6 col-8">
                <div className="">
                  <h3 className="mb-0">Our Best Voucher</h3>
                  <p className="mb-0 ps-2">Best Voucher Categories</p>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="text-end">
                  <button class="btn mb-0 bg-gradient-primary viewallbutton text-white p-0 py-1 px-3 shadow-none" onClick={(e) => navigate("/allvoucher")}>View all <span> <i class="fa-solid fa-arrow-right"></i></span></button>
                </div>
              </div>


              {[1, 2, 3, 4, 5, 6].map((item) => {
                return (
                  <>
                    <div className="col-md-6">
                      <div className="" style={{ position: "relative" }}>
                        <img
                          src={imgtab}
                          style={{ height: "120px" }}
                          className="w-100 mt-3"
                          alt=""
                        />
                        <div className="absrow">
                          <h6 className="text-white">Hidesign, Gomti Nagar</h6>
                          <h6 style={{ width: "60%", fontSize: "14px" }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.
                          </h6>
                          <div className="d-flex justify-content-between vouchersizebig" >
                            <button
                              className="btn mb-0 bg-gradient-primary text-white p-0 py-1 px-3 mt-1 me-3"
                              style={{ fontSize: "12px" }}
                            >
                              <del className="me-2">₹ 2000</del>  ₹ 1600
                            </button>
                            {/* <button
                            className="btn mb-0 bg-gradient-primary text-white p-0 py-1 px-3 mt-1"
                            style={{ fontSize: "12px" }}
                            disabled
                          >
                            Details
                          </button> */}
                          </div>
                        </div>
                        <div className="absimg">
                          <img loading="lazy" src={voucherlogo} alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>


        <section>
          <div className="container">
            <div
              className="row bg-white customshadow rounded-3 p-3 mb-5 align-items-center"
            // data-aos="fade-right"
            // data-aos-duration="1000"
            >
              <div className="col-md-6 col-8">
                <div className="mainheadingbox">
                  <h3 className="mb-0">Started Events</h3>
                  <p className="mb-0 ps-2">Find nearby events</p>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="text-end">
                  <button
                    className="btn mb-0 bg-gradient-primary viewallbutton text-white p-0 py-1 px-3"
                    onClick={(e) =>
                      navigate(`/eventspage`, { state: `${"Events"}` })
                    }
                  >
                    View all{" "}
                    <span>
                      {" "}
                      <i class="fa-solid fa-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
              <Slider {...eventsscroll}>
                {startevent.map((item, index) => {
                  return (
                    <>
                      <div
                        className="h-100"
                        style={{ cursor: "pointer", margin: "20px" }}
                      // onClick={(e) => handlevendorpage(item)}
                      >
                        <div className="eventbox position-relative  rounded-3 mt-2 h-100">
                          <img
                            src={item.img}
                            alt=""
                            className="img-fluid rounded-1 w-100"
                            style={{ height: "180px" }}
                          />
                          <div className={`tag text-white text-uppercase bg-green`}  >
                            Started
                          </div>
                          <div className={`date text-white text-uppercase bg-white shadow`}  >
                            <p className="mb-0 issuedate">{item.date}</p>
                            <p className="mb-0">{item.month}</p>
                          </div>
                          <div className="contentabs" style={{ bottom: "12px" }}>
                            <h6 className="mb-0 text-white">  {item.title}</h6>
                            <p className="mb-0 text-white">{item.para}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>

        <section class="breadcrumb-area breadcrumb-bg">
          <div class="container h-100">
            <div class="row justify-content-end h-100 align-items-center">
              <div class="col-lg-4">
                <div class="breadcrumb-content ">
                  <div className="bg-dark text-white  p-3 py-5 rounded-4">
                    <h3 className="text-white">Are you a Bussiness Owner ?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ex fugit eaque ipsam ad numquam. Magnam eius saepe ab
                      labore iusto laborum cupiditate obcaecati natus voluptates
                      commodi aspernatur, vel dolores ducimus!
                    </p>
                    <button className="btn mb-0 bg-danger text-white p-0 py-1 px-3">
                      Join Us Now{" "}
                      <span>
                        {" "}
                        <i class="fa-solid fa-arrow-right"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="section-padding homepage-great-deals bg-white">
          <div class="container">
            <div class="row">
              <div className="col-md-12">
                <div className="">
                  <MultipleImages />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />

        {/* <Modal show={show} size="xl" onHide={handleClose}>
          <Modal.Header closeButton className="productVideo">
           
          </Modal.Header>
          <Modal.Body style={{ position: "relative" }}>
            <div
              className="logo"
              style={{
                position: "absolute",
                zIndex: "999",
                left: "30px",
                top: "20px",
              }}
            >
              <img
                src={logo}
                className="img-fluid"
                style={{ width: "150px" }}
                alt=""
              />
            </div>
            
            <video src={marketvideo} className="img-fluid" autoPlay muted></video>
            <div
              className="btn-group"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
                bottom: "50px",
              }}
            >
              <a
              href="https://nitin.frantic.in/signin/"
                style={{
                  padding: "10px 20px",
                  margin: "0 30px",
                  fontSize: "16px",
                  cursor: "pointer",
                  width: "200px",
                  
                }}
                onClick={(e) => setShow(false)}
                className="btn bg-gradient-primary  text-white rounded-3"
              >
                Continue with Login
              </a>
              <button
                style={{
                  padding: "10px 20px",
                  margin: "0 30px",
                  fontSize: "16px",
                  cursor: "pointer",
                  width: "200px",
                  
                }}
                onClick={(e) => setShow(false)}
                className="btn bg-gradient-primary   text-white rounded-3"
              >
                Skip
                <span className="ms-2">
                  <i class="fa-solid fa-angles-right"></i>
                  <i class="fa-solid fa-angles-right"></i>
                </span>
              </button>
            </div>
          </Modal.Body>
        </Modal> */}
      </>
    </>
  );
}

export default Home;
