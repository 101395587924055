import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import './assets/style.css'
import { BrowserRouter } from 'react-router-dom';
import ThemeRoutes from './routes';
import './responsive/myresponsive.css';


function App() {
  return (
    <>
      <BrowserRouter>
        <ThemeRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
