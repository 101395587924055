import axios from "axios"
import { BaseUrl } from "./BaseUrl"

export const registerapi = async (requestdata) => {
    try {
        const res = await axios.post(`${BaseUrl}register`, requestdata);
        return res;
    } catch (err) {
        console.log(err);
    }
};


export const getapi = async (endpoint, requestdata) => {
    try {
        const res = await axios.get(`${BaseUrl}${endpoint}`)
        return res;
    } catch (err) {
        console.log(err);
    }
}