import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

function CategoriesList(props) {
  const navigate = useNavigate();

  // const handlevendorpage = (item) => {
  //   navigate(`/vendordetail/${props.slug}`, { state: item });
  // };

  const handlevendorpage = (item) => {
    navigate(`/vendor/${props.slug}`);
  };



  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false, // Ensure arrows are enabled

    responsive: [
      {
        breakpoint: 768, // define breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true // Enable arrows for navigation on mobile devices
        }
      }
    ]
  };









  return (
    <>
      <div
        className="row 
         customshadow  align-items-center
        "
      // data-aos={props.aos}
      // data-aos-duration={props.timing}
      // style={{ border: "1px solid grey" }}
      >
        <div className="col-md-6 col-8">
          <div className="mainheadingbox">
            <h3 className="mb-0">{props.title}</h3>
            <p className="mb-0 ps-2">{props.para}</p>
          </div>
        </div>
        <div className="col-md-6 col-4">
          <div className="text-end">
            <button
              className="btn mb-0 viewallbutton   p-0 py-1 px-3 text-white bg-gradient-primary me-4
              "
              onClick={(e) => navigate('/categorieslist')}
            >
              View All
            </button>
          </div>
        </div>
        <Slider {...settings}>
          {props.data.map((item, index) => {
            return (
              <>
                <div
                  className="h-100"
                  style={{ cursor: "pointer", margin: "20px" }}
                  onClick={(e) => handlevendorpage(item)}
                >
                  <div className="boxcat position-relative bg-white shadow  rounded-3 mt-2 h-100" >
                    <img
                      loading="lazy"
                      src={item.img}
                      alt=""
                      className="img-fluid rounded-1 w-100 categoryimage"

                    />
                    <div className={`tag text-white text-uppercase ${item.tag == "food" ? "bg-green" : item.tag == "Restaurant" ? "bg-orange" : item.tag == "Clothes" ? "bg-blue" : "bg-pink"}`}  >
                      {item.tag}
                    </div>
                    <div className="pt-3 d-flex align-items-center p-3 flex-wrap justify-content-between">
                      {index == 2 ? <button className="border-outline rounded-3 fw-bold px-2" style={{ border: "2px solid #76bd98", color: "#76bd98", background: "transparent" }}>Now Open</button> : <button className="border-outline rounded-3 fw-bold px-2" style={{ border: "2px solid #eba7ab", color: "#eba7ab", background: "transparent" }}>Closed</button>}
                      {/* <h5>{item.title}</h5> */}
                      {/* <p>
                        {item.para}
                      </p> */}
                      <div className="d-flex align-items-center gap-2 boxcatcontent">
                        <div className="">
                          <h6 className="mb-0">Super</h6>
                          <p className="mb-0">7 Review(s)</p>
                        </div>
                        <div className="reviewbox p-1 rounded-1" style={{ background: "#f0f0f0" }}>
                          <i class="fa-solid fa-star text-warning"></i> <span className="fw-bold text-dark">4.3</span>
                        </div>
                      </div>
                      <div className="contentabs">
                        <h6 className="mb-0 text-white">  {item.title}</h6>
                        <p className="mb-0 text-white">
                          {item.para.split(' ').slice(0, 5).join(' ')}
                        </p>
                      </div>



                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default CategoriesList;
