// import AllCategories from "../Pages/AllCategories";
// import Categories from "../Pages/Categories";
// import EventPage from "../Pages/EventPage";
// import Home from "../Pages/Home";
// import Login from "../AuthPage/Login";
// import Magzine from "../Pages/Magzine";
// import Magzinedetail from "../Pages/Magzinedetail";
// import OurBestVoucher from "../Pages/OurBestVoucher";
// import Shop from "../Pages/Shop";
// import VendorDetail from "../Pages/VendorDetail";
// import Videosection from "../Pages/Videosection";
// import Layout from "../layouts";
// import CategoryPage from "../layouts/CategoryPage";
// import Signup from "../AuthPage/Signup";
// import Profile from "../Pages/Profile";
// import Faq from "../Pages/Faq";
// import Terms from "../Pages/Terms";
// import Utsava from "../Pages/Utsava";
// import Membership from "../Pages/Membership";
// import EventDetail from "../Pages/EventDetail";
// import VendorCard from "../Components/VendorCard";
// import AllVendor from "../Pages/AllVendor";
// import CartPage from "../Pages/Cart/CartPage";

// const MainRoutes = {
//   title: "Home",
//   id: 1,
//   path: "/",
//   element: <Layout />,
//   children: [
//     {
//       path: "/",
//       element: <Videosection />,
//     },
//     {
//       path: "/home",
//       element: <Home />,
//     },
//     {
//       path: "/categories",
//       element: <Categories />,
//     },
//     {
//       path: "/allcategories",
//       element: <AllCategories />,
//     },
//     {
//       path: "/vendordetail/:id",
//       element: <VendorDetail />,
//     },
//     {
//       path: "/magzine",
//       element: <Magzine />,
//     },
//     {
//       path: "/magzinedetail",
//       element: <Magzinedetail />,
//     },
//     {
//       path: "/categorieslist",
//       element: <CategoryPage />,
//     },
//     {
//       path: "/shop",
//       element: <Shop />,
//     },
//     {
//       path: "/allvoucher",
//       element: <OurBestVoucher />,
//     },
//     {
//       path: "/eventspage",
//       element: <EventPage />,
//     },
//     {
//       path: "/eventsdetail",
//       element: <EventDetail />,
//     },
//     {
//       path: "/login",
//       element: <Login />,
//     },
//     {
//       path: "/utsava",
//       element: <Utsava />,
//     },
//     {
//       path: "/membership",
//       element: <Membership />,
//     },
//     {
//       path: "/signup",
//       element: <Signup />,
//     },
//     {
//       path: "/profile",
//       element: <Profile />,
//     },
//     {
//       path: "/faq",
//       element: <Faq />,
//     },
//     {
//       path: "/terms",
//       element: <Terms />,
//     },
//     {
//       path: `/vendor/:id`,
//       element: <AllVendor />,
//     },
//     {
//       path: `/cartpage`,
//       element: <CartPage />,
//     },
//   ],
// };
// export default MainRoutes;


import React, { Suspense, lazy } from 'react';
import Layout from "../layouts";
import Loading from '../Loader/Loading';

const AllCategories = lazy(() => import("../Pages/AllCategories"));
const Categories = lazy(() => import("../Pages/Categories"));
const EventPage = lazy(() => import("../Pages/EventPage"));
const Home = lazy(() => import("../Pages/Home"));
const Login = lazy(() => import("../AuthPage/Login"));
const Magzine = lazy(() => import("../Pages/Magzine"));
const Magzinedetail = lazy(() => import("../Pages/Magzinedetail"));
const OurBestVoucher = lazy(() => import("../Pages/OurBestVoucher"));
const Shop = lazy(() => import("../Pages/Shop"));
const VendorDetail = lazy(() => import("../Pages/VendorDetail"));
const Videosection = lazy(() => import("../Pages/Videosection"));
const CategoryPage = lazy(() => import("../layouts/CategoryPage"));
const Signup = lazy(() => import("../AuthPage/Signup"));
const Profile = lazy(() => import("../Pages/Profile"));
const Faq = lazy(() => import("../Pages/Faq"));
const Terms = lazy(() => import("../Pages/Terms"));
const Utsava = lazy(() => import("../Pages/Utsava"));
const Membership = lazy(() => import("../Pages/Membership"));
const EventDetail = lazy(() => import("../Pages/EventDetail"));
const VendorCard = lazy(() => import("../Components/VendorCard"));
const AllVendor = lazy(() => import("../Pages/AllVendor"));
const CartPage = lazy(() => import("../Pages/Cart/CartPage"));

const MainRoutes = {
  title: "Home",
  id: 1,
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Videosection />
        </Suspense>
      ),
    },
    {
      path: "/home",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: "/categories",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Categories />
        </Suspense>
      ),
    },
    {
      path: "/allcategories",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <AllCategories />
        </Suspense>
      ),
    },
    {
      path: "/vendordetail/:id",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <VendorDetail />
        </Suspense>
      ),
    },
    {
      path: "/magzine",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Magzine />
        </Suspense>
      ),
    },
    {
      path: "/magzinedetail",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Magzinedetail />
        </Suspense>
      ),
    },
    {
      path: "/categorieslist",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <CategoryPage />
        </Suspense>
      ),
    },
    {
      path: "/shop",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Shop />
        </Suspense>
      ),
    },
    {
      path: "/allvoucher",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <OurBestVoucher />
        </Suspense>
      ),
    },
    {
      path: "/eventspage",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <EventPage />
        </Suspense>
      ),
    },
    {
      path: "/eventsdetail",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <EventDetail />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/utsava",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Utsava />
        </Suspense>
      ),
    },
    {
      path: "/membership",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Membership />
        </Suspense>
      ),
    },
    {
      path: "/signup",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Signup />
        </Suspense>
      ),
    },
    {
      path: "/profile",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Profile />
        </Suspense>
      ),
    },
    {
      path: "/faq",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Faq />
        </Suspense>
      ),
    },
    {
      path: "/terms",
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <Terms />
        </Suspense>
      ),
    },
    {
      path: `/vendor/:id`,
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <AllVendor />
        </Suspense>
      ),
    },
    {
      path: `/cartpage`,
      element: (
        <Suspense fallback={<div><Loading /></div>}>
          <CartPage />
        </Suspense>
      ),
    },
  ],
};

export default MainRoutes;
