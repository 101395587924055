




import React, { useEffect, useRef, useState } from "react";
import Banner from "../Components/Banner";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import removetag from "../assets/removetaglogo.png";

import "../assets/mycss.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Select from "react-select";
import a from "../assets/a.png"
import mike from "../assets/mike.gif"
import locationimg from "../assets/locationgif.gif"

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Header() {
  const location = useLocation()
  const locationSelectRef = useRef(null);
  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [dropdownactive, setdropdownactive] = useState(true)
  const [audio, setaudio] = useState(false)
  const [search, setsearch] = useState("")
  const [showdrop, setshowdrop] = useState(false)


  const toggleSearch = () => {
    setIsActive(!isActive);
  };

  let address = localStorage.getItem("address")

  useEffect(() => {
    // Scroll to the top when the component mounts or when the location changes
    window.scrollTo(0, 0);
    setsearch(address)

  }, []);

  // useEffect(() => {
  //   if (search) {
  //     localStorage.setItem("address", search)
  //     localStorage.setItem("latitude", coords.latitude)
  //     localStorage.setItem("longitude", coords.longitude)
  //   }
  // }, [])

  useEffect(() => {
    AOS.init() // Initialize AOS with desired options

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const locations = [
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Kashmir", label: "Kashmir" },
    { value: "Goa", label: "Goa" },
    { value: "kolkata", label: "Kolkata" },
    // Add more locations as needed
  ];

  const destination = [
    {
      name: "Kolkata"
    },
    {
      name: "Delhi"
    },
    {
      name: "Noida"
    },
    {
      name: "Punjab"
    },
    {
      name: "Uttar Pradesh"
    },
  ]

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const [shouldAnimate, setShouldAnimate] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    // Check the scroll position
    if (window.scrollY > 100) {
      // Add animation class if scroll position is greater than 100px
      setShouldAnimate(true);
    } else {
      // Remove animation class if scroll position is less than 100px
      setShouldAnimate(false);
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault()
  };








  // useEffect(() => {

  //   locationSelectRef.current.focus();
  // }, []);

  const handleSelectFocus = () => {
    setIsSelectFocused(true);
  };

  const handleSelectBlur = () => {
    setIsSelectFocused(false);
  };



  const handleDropdownClick = () => {
    setdropdownactive(!dropdownactive);
  };



  // const handleaudio = (e) => {
  //   e.preventDefault()
  //   setaudio(!audio)
  //   if (!audio) {
  //     SpeechRecognition.startListening()
  //   } else {
  //     SpeechRecognition.stopListening()
  //   }

  //   setTimeout(() => {
  //     setaudio(false)
  //   }, 6000);
  // }

  const handleaudio = async (e) => {
    e.preventDefault();

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setaudio(!audio);
      if (!audio) {
        SpeechRecognition.startListening();
      } else {
        SpeechRecognition.stopListening();
      }

      setTimeout(() => {
        setaudio(false);
      }, 6000);
    } catch (error) {
      // Handle the case where microphone access is denied or not supported
      console.error('Error accessing microphone:', error);
      alert("Please allow access your microphone to use these features")
      // You can show a message to the user here to inform them about the need for microphone access
    }
  };

  const handlesearch = (e) => {
    if (transcript) {
      setsearch(transcript)
    }
  }


  const handledrop = () => {
    setshowdrop(!showdrop)
  }


  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    handlesearch()
  }, [transcript])

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }




  return (
    <>
      <nav className={`navbar  bg-white navbar-expand-lg ${shouldAnimate ? 'fixed-top' : 'shadow'}`} >
        <div className={`container-fluid${shouldAnimate ? ' addanimation' : ''}`}>
          <Link className="navbar-brand bg-white p-2 rounded-3" to="/">
            <div className="position-relative">
              <img loading="lazy" src={removetag} className="headerlogo" alt="" />
              <h6 className="mb-0 tagline" >spreading happiness spreading smiles...</h6>
            </div>
            {/* <img loading="lazy" src={logo} className="headerlogo" alt="" /> */}

          </Link>

          <ul className="desktopviewlocation">
            <li className="nav-item ">

              <div className="locationsearch position-relative">
                <div className="">
                  <input type="text" placeholder="Select Location" style={{ cursor: "pointer" }} readOnly onClick={(e) => handledrop(e)} value={search} onChange={(e) => setsearch(e.target.value)} />
                  <button className="crossbtn" onClick={(e) => setsearch("")}><i class="fa-solid fa-xmark" ></i></button>
                  <button className={`mikebtn ${!audio ? "" : "bg-white"}`} onClick={(e) => handleaudio(e)}>
                    {!audio ? <i class="fa-solid fa-microphone text-white px-1"></i> : <img loading="lazy" src={mike} style={{ width: "25px" }}></img>}
                  </button>
                  <button className="search_place"><i class="fa-solid fa-magnifying-glass text-white"></i></button>
                </div>
                <div className="locationdropdown bg-white shadow" >

                  <div className=" py-2 dropdownsort w-100">

                    {destination.map((item) => {
                      return (
                        <>
                          <p onClick={(e) => { setsearch(item.name); setshowdrop(false) }}><span><img loading="lazy" src={locationimg} style={{ width: "30px" }} alt="" /></span> <span style={{ fontSize: "16px", position: "relative", top: "3px" }}>{item.name}</span> </p>
                        </>
                      )
                    })}
                  </div>


                </div>
              </div>




            </li>
          </ul>



          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fa-solid fa-bars "></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item mt-2">
                <NavLink className="nav-link" to="/home" activeClassName="active">
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item mt-2">
                <NavLink className="nav-link" to="/categories" activeClassName="active">
                  Categories
                </NavLink>
              </li>
              <li className="nav-item mt-2">
                <NavLink className="nav-link" to="/magzine" activeClassName="active">
                  Magazine
                </NavLink>
              </li>
              <li className="nav-item mt-2">
                <NavLink className="nav-link" to="/eventspage" activeClassName="active">
                  Events
                </NavLink>
              </li>



              <li className="nav-item dropdown mt-2" onClick={handleDropdownClick}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Pages <span className="badge badge-danger badge-top">NEW</span>
                </a>
                <div className={`dropdown-menu dropdown-menu-lg p-4 ${!dropdownactive ? "activedropdown" : "notactive"}`} aria-labelledby="navbarDropdown">
                  <div className="row no-gutters">
                    <div className="col-12 col-lg-6">
                      <h6 className="dropdown-header">Account</h6>
                      <NavLink className="dropdown-item" to="/profile">
                        Profile
                      </NavLink>
                      <NavLink className="dropdown-item" to="/login">
                        Login
                      </NavLink>
                      {/* <NavLink className="dropdown-item" to="/signup">
                        Sign Up as User
                      </NavLink> */}
                      <NavLink className="dropdown-item" to="/signup">
                        Sign Up as Vendor
                      </NavLink>
                    </div>
                    <div className="col-12 col-lg-6">
                      <h6 className="dropdown-header">Company</h6>
                      <NavLink className="dropdown-item" to="/faq">
                        FAQ
                      </NavLink>
                      <NavLink className="dropdown-item" to="/terms">
                        Terms
                      </NavLink>
                    </div>
                  </div>
                </div>
              </li>

              {/* <li className="nav-item cartlink ">
                <NavLink className="nav-link" to="/cartpage" activeClassName="active">
                  <i class="fa-solid fa-cart-shopping"></i>
                </NavLink>
              </li> */}








              <li className="nav-item mobileviewlocation">

                <div className="locationsearch">
                  <div className="">
                    <input type="text" placeholder="Select Location" style={{ cursor: "pointer" }} readOnly onClick={(e) => handledrop(e)} value={search} onChange={(e) => setsearch(e.target.value)} />
                    <button className="crossbtn" onClick={(e) => setsearch("")}><i class="fa-solid fa-xmark" ></i></button>
                    <button className={`mikebtn ${!audio ? "" : "bg-white"}`} onClick={(e) => handleaudio(e)}>
                      {!audio ? <i class="fa-solid fa-microphone text-white px-1"></i> : <img loading="lazy" src={mike} style={{ width: "25px" }}></img>}
                    </button>
                    <button className="search_place"><i class="fa-solid fa-magnifying-glass text-white"></i></button>
                  </div>
                  {showdrop && <div className="locationdropdown bg-white shadow" >

                    <div className=" py-2 dropdownsort w-100">

                      {destination.map((item) => {
                        return (
                          <>
                            <p onClick={(e) => { setsearch(item.name); setshowdrop(false) }}><span><img loading="lazy" src={locationimg} style={{ width: "30px" }} alt="" /></span> <span style={{ fontSize: "16px", position: "relative", top: "3px" }}>{item.name}</span> </p>
                          </>
                        )
                      })}
                    </div>
                  </div>}
                </div>
              </li>

              <li className="nav-item searchbox mobileviewserchbox  d-flex" >
                <div className=""></div>
                <div className={`wrap me-3 active`}>
                  <form onSubmit={(e) => handlesubmit(e)}>
                    <input
                      type="text"
                      className={`input active`}
                      placeholder="Search"
                    />
                  </form>
                  <i className="fa fa-search" aria-hidden="true" onClick={toggleSearch}></i>
                </div>

              </li>
              <li className="nav-item searchbox desktopsearchbox d-flex">
                <div className=""></div>
                <div className={`wrap me-3 ${isActive ? 'active' : ''}`}>
                  <form onSubmit={(e) => handlesubmit(e)}>
                    <input
                      type="text"
                      className={`input ${isActive ? 'active' : ''}`}
                      placeholder="Search"
                    />
                  </form>
                  <i className="fa fa-search" aria-hidden="true" onClick={toggleSearch}></i>
                </div>
              </li>
              {/* <li className="nav-item logomobile mt-1"><img loading="lazy" src={a} className="img-fluid pt-2" style={{ height: "34px" }} alt="" /></li> */}
              <li className="nav-item my-2"></li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;


