import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap';

import { useGeolocated } from 'react-geolocated';

function Geolocater() {
    



    return (
        <>
            <div id="map" style={{ width: "100%", height: "400px" }}></div>;
        </>
    )
}

export default Geolocater;
