import React, { useEffect, useState } from "react";
import Select from "react-select";
import locationimg from "../assets/location.png";
import Slider from "react-slick";
import food from "../assets/food.png";
import education from "../assets/scholarship.png";
import entertainment from "../assets/entertainment.png";
import brand from "../assets/brand.png";
import automobile from "../assets/automobile.png";
import fitness from "../assets/fitness.png";
import store from "../assets/store.png";
import needs from "../assets/needs.png";
import spa from "../assets/spa.png";
import cosmetics from "../assets/cosmetics.png";
import boots from "../assets/boots.png";
import elect from "../assets/elect.png";
import sofa from "../assets/sofa.png";
import healthcare from "../assets/healthcare.png";
import resorts from "../assets/resorts.png";
import material from "../assets/material.png";
import food1 from "../assets/food-1.png";

import event from "../assets/event.png";
import { useNavigate } from "react-router-dom";
import Typer from "./Typer";
import a from "../assets/a.png"
import { getapi } from "../Api/Api";
import { BaseUrl } from "../Api/BaseUrl";
import Loading from "../Loader/Loading";

function Banner() {
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);

  const [bannerdata, setbannerdata] = useState([])
  const [loading, setloading] = useState(false)
  const [restbanner, setrestbanner] = useState([])

  const toggleSearch = () => {
    setIsActive(!isActive);
  };

  const handlesubmit = (e) => {
    e.preventDefault()
  };

  const locations = [
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Kashmir", label: "Kashmir" },
    { value: "Goa", label: "Goa" },
    { value: "kolkata", label: "Kolkata" },
  ];



  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,

    responsive: [
      {
        breakpoint: 768, // define breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };


  // const bannerdata = [
  //   {

  //     img: "https://img.freepik.com/free-vector/happy-raksha-bandhan-cultural-festival-classic-banner-design-vector_1055-18362.jpg?t=st=1721116654~exp=1721120254~hmac=076f95a8960cd9161c48bb79d6c57f6e84b3c09fa66ba7d449ed27156f767d0d&w=1480",
  //     title: "Galaxy Folding Phones"
  //   },
  //   {

  //     img: "https://img.freepik.com/free-vector/horizontal-banner-template-india-independence-day-celebration_23-2150602619.jpg?t=st=1721116591~exp=1721120191~hmac=bafb4a25379a6ca1d127bb2a76bb344add3c2be00672f302413770eeb99b4a6a&w=1380",
  //     title: "Modern Mobile Phone"
  //   },
  //   {

  //     img: "https://static.vecteezy.com/system/resources/previews/020/866/398/non_2x/happy-gudi-padwa-maharashtra-new-year-festival-illustration-vector.jpg",
  //     title: "Motorola Edge Phone"
  //   }
  // ]

  const handleget = async () => {
    try {
      let res = await getapi('banner');
      setloading(true)
      console.log(res);
      if (res.data.error == 0) {
        setrestbanner(res.data.data)

        let filteredRes = res.data.data.filter(item => item.position == 1);
        setbannerdata(filteredRes[0])
        setloading(false)
      } else {
        setloading(false)
      }



    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  useEffect(() => {
    handleget()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <section class="mainBanner mt-3">
        <div class="container-fluid h-100">
          <div class="row h-100">

            <div className="col-md-9 position-relative">
              {/* <Slider {...settings}>
                {bannerdata.map((item, index) => (
                  <div key={index} className="slider-item">
                    <div className="h-100">
                      <img

                        src={item.img}
                        alt=""
                        className="img-fluid rounded-4 bannerimgstyle h-100 w-100"
                      />
                    </div>

                  </div>
                ))}
              </Slider> */}
              <Slider {...settings}>
                {bannerdata?.image?.map((item, index) => (
                  <div key={index} className="slider-item">
                    <div className="h-100">
                      <img
                        onError={(e) => {
                          e.target.onerror = null; // Prevents infinite loop if fallback image also fails
                          e.target.src = "https://images.unsplash.com/photo-1513151233558-d860c5398176?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                        }}
                        src={`${BaseUrl}${item}`}
                        loading="lazy"
                        alt=""
                        className="img-fluid rounded-4 bannerimgstyle h-100 w-100"
                      />
                    </div>

                  </div>
                ))}


              </Slider>
            </div>
            <div className="col-md-3 mobileviewnone">
              <div className="row gy-3">
                {restbanner.filter(item => item.position != 1).map((item) => {
                  return (
                    <div key={item.id} className="col-md-12">
                      <div className="position-relative">
                        <img
                          src={`${BaseUrl}${item.image}`}
                          alt={item.altText || ""}
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop if fallback image also fails
                            e.target.src = "https://images.unsplash.com/photo-1513151233558-d860c5398176?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                          }}
                          className="img-fluid rounded-4 secondbannerimg w-100"
                        />
                      </div>
                    </div>
                  );
                })}
                {/* <div className="col-md-12">
                  <div className="position-relative">
                    <img
                      
                      src="https://cdn.abhibus.com/2024/04/Baisakhi-2024-Date-History-Celebration.jpg"
                      alt="" className="img-fluid rounded-4 secondbannerimg w-100" />
                    
                  </div>
                </div> */}

                {/* <div className="col-md-12">
                  <div className="position-relative">
                    <img

                      src="https://png.pngtree.com/thumb_back/fh260/background/20210420/pngtree-shree-ram-navami-banner-with-realistic-bow-and-lantern-image_643020.jpg"
                      alt="" className="img-fluid rounded-4 secondbannerimg" />

                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;


